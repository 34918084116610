// src/components/HeroSection.js
import React from 'react';
import backgroundImage from '../assets/head.png'; // Ensure the path is correct
import logo from '../assets/logoCowork.svg';



const HeroSection = () => {
  return (
    <div id='home'>
      <div className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-20 w-full tracking-wide min-h-[820px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        srcSet={backgroundImage}
        className="object-cover absolute inset-0 size-full"
      />
      <div className="flex relative flex-col mb-12 w-full max-w-[1229px] max-md:mb-10 max-md:max-w-full">
        <div className="flex gap-5 justify-between w-full text-sm text-neutral-200 max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            srcSet={logo}
            className="zoom-in shrink-0 max-w-full aspect-[1.79] w-[184px]"
          />
          <div className="flex gap-5 justify-between items-center my-auto max-md:flex-wrap">
            <a href="#home"  className="self-stretch my-auto font-bold text-white">Home</a>  
            <a href="#event"  className="self-stretch my-auto zoom-in">Events</a>
            <a href="#facility" className="self-stretch my-auto zoom-in">Facilities</a>
            <a 
               href="#location" 
              //  target="_blank"
              //  rel="noopener noreferrer"
               className="text-gray-400 hover:text-white zoom-in">Location</a>
            <a href="#about" className="text-gray-400 hover:text-white zoom-in">About Us</a>
            <a href="#contact" className="zoom-in justify-center self-stretch px-7 py-4 font-semibold text-white bg-[#014C8D] hidden md:block max-md:px-5">
            Schedule a Visit
            </a>
          </div>
        </div>
        <div className="flex flex-col mt-28 ml-7 max-w-full text-white w-[567px] max-md:mt-10">
        <div className="text-7xl font-bold capitalize leading-[82px] max-md:max-w-full max-md:text-4xl max-md:leading-[50px]">
  <div className='zoom-in'>Work Together</div>
  <div className='zoom-in'>Grow Together</div>
  <div className='zoom-in'>Share Together</div>
</div>

          <div className="mt-4 text-base leading-7 max-md:max-w-full">
            Our coworking spaces let you choose to work with others in an
            open-plan area, or a shared office.{" "}
          </div>
          <a href="#contact" className="justify-center self-start px-8 py-6 mt-9 text-1xl font-semibold tracking-wider leading-6 uppercase bg-[#014C8D] max-md:px-5 zoom-in">
          Schedule a Visit
          </a>
        </div>
      </div>
    </div>
    </div>
        

    
    
  );
};

export default HeroSection;
