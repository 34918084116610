import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css'; // Ensure Tailwind CSS is imported
import '@fortawesome/fontawesome-free/css/all.min.css';

// Import your components
import HomeScreen from './components/HeroSection';
import SecondSection from './components/second';
import Facilities from './components/Facilities';
import TestSection from './components/testominial';
import WorkSpace from './components/Workspace';
import FormSection from './components/Form';
import SpaceOverview from './components/SpaceOverview';
import Footer from './components/Footer';
import NewsletterSection from './components/newsletter';
import EventsPage from './components/event';

function App() {
  return (
    <div>
      <HomeScreen />
      <SecondSection />
      <WorkSpace />
      <FormSection />
      <Facilities />
      <TestSection />
      <SpaceOverview />
      <EventsPage />  
      <NewsletterSection />
      <Footer />
    </div>
  );
}

export default App;
