import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import one from './../assets/slider/1.jpg';
import two from './../assets/slider/2.jpg';
import three from './../assets/slider/3.jpg';
import four from './../assets/slider/4.jpg';
import five from './../assets/slider/5.jpg';
import six from './../assets/slider/6.jpg';
import seven from './../assets/slider/7.jpg';
import eight from './../assets/slider/8.jpg';
import nine from './../assets/slider/9.jpg';
import ten from './../assets/slider/10.jpg';
import eleven from './../assets/slider/11.jpg';
import twetle from './../assets/slider/12.jpg';
import thirt from './../assets/slider/13.jpg';
import fouth from './../assets/slider/14.jpg';
import fifth from './../assets/slider/15.jpg';
import sixth from './../assets/slider/16.jpg';
import seventh from './../assets/slider/17.jpg';
import eighth from './../assets/slider/18.jpg';
import ninth from './../assets/slider/19.jpg';
import tenth from './../assets/slider/20.jpg';
import twentyth from './../assets/slider/21.jpg';
import thwenth from './../assets/slider/22.jpg';
import twotwo from './../assets/slider/23.jpg';
import twothree from './../assets/slider/24.jpg';
import towdour from './../assets/slider/25.jpg';
import towfive from './../assets/slider/26.jpg';
import twosiz from './../assets/slider/27.jpg';
import twosenevn from './../assets/slider/28.jpg';
import toweight from './../assets/slider/29.jpg';
import twonin from './../assets/slider/30.jpg';

// import './workspace.css'
import './Slider.css';



const SpaceOverview = () => {
  const [currentIndex, setCurrentIndex] = useState(2); // Start with the center image
  const [itemsToShow, setItemsToShow] = useState(4);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    const updateItemsToShow = () => {
      if (window.innerWidth < 768) {
        setItemsToShow(1);
      } else {
        setItemsToShow(4);
      }
    };

    updateItemsToShow();
    window.addEventListener('resize', updateItemsToShow);
    return () => window.removeEventListener('resize', updateItemsToShow);
  }, [controls, inView]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: 'easeOut' },
    },
  };

  const images = [
    two, three, four, five, six, seven, eight, nine, ten, eleven,
    twetle, thirt, fouth, fifth, sixth, seventh, eighth, ninth, tenth,
    twentyth, thwenth, twotwo, twothree, towdour, towfive, twosiz, twosenevn, toweight, twonin,
  ];

  return (
    <div id="space">
      <div className="py-8">
        <motion.div
          ref={ref}
          className="text-center mb-8"
          initial="hidden"
          animate={controls}
          variants={fadeInUp}
        >
          <h2 className="text-4xl font-bold text-center mb-10">Space Overview</h2>
          <p className="text-gray-500 mt-2 px-4 md:px-20 lg:px-40 xl:px-60">
            Discover Our Vibrant Coworking Spaces Designed To Foster Creativity And Productivity. Browse Through Our Gallery To See Thoughtfully Crafted Work Areas, Collaborative Zones, And Top-Notch Amenities. Experience The Essence Of Our Coworking Community Through These Stunning Images.
          </p>
        </motion.div>

        <motion.div
          className="w-full"
          initial="hidden"
          animate={controls}
          variants={fadeInUp}
        >
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false} // Hides the dots
            autoPlay={true}
            interval={3000}
            centerMode={itemsToShow > 1}
            centerSlidePercentage={itemsToShow > 1 ? 25 : 100}
            className="max-w-full"
            selectedItem={currentIndex}
            onChange={(index) => setCurrentIndex(index)}
          >
            {images.map((image, index) => (
              <div key={index} className="flex justify-center">
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className={`object-cover ${
                    index === currentIndex ? 'md:h-[320px] md:w-[320px] h-[250px] w-[250px]' : 'md:h-[300px] md:w-[300px] h-[220px] w-[220px]'
                  }`}
                />
              </div>
            ))}
          </Carousel>
        </motion.div>
      </div>
    </div>
  );
};

export default SpaceOverview;