// src/components/Facilities.js
import React from 'react';
import internetIcon from '../assets/i1.png';
import teaCoffeeIcon from '../assets/i2.png';
import meetingRoomsIcon from '../assets/i9.png';
import houseKeepingIcon from '../assets/i4.png';
import primeLocationIcon from '../assets/i5.png';
import receptionIcon from '../assets/i10.png';
import conferenceRoomIcon from '../assets/i7.png';
import cafeteriaIcon from '../assets/i8.png';
import powerback from '../assets/i11.png';
import acFacility from '../assets/ac.png'
import './style.css'; // Adjust the path if your CSS file is in a different directory


const facilities = [
  { icon: internetIcon, text: 'High Speed Internet', subText: '(Wi-Fi and lease line backup)' },
  { icon: teaCoffeeIcon, text: 'Free Tea & Coffee', subText: '(Includes biscuits)' },
  { icon: meetingRoomsIcon, text: 'Host Events', subText: '(Spacious venue with support staff)' },
  { icon: houseKeepingIcon, text: 'House Keeping', subText: '(24-hour service)' },
  { icon: primeLocationIcon, text: 'Prime Location', subText: '(Near airport and 3 & 5-star hotels)', },
  { icon: receptionIcon, text: 'Ready Move-In Setup', subText: '(Ideal for BPO and call centers)' },
  { icon: conferenceRoomIcon, text: 'Conference Room', subText: '(Two spacious rooms)' },
  { icon: cafeteriaIcon, text: 'Cafeteria', subText: '(Spacious area for meals and team gatherings)' },
  { icon: powerback, text: 'Double Powerbackup', subText: '(Adani and Tata powerback)' },
  { icon: acFacility, text: 'Air Conitioner ', subText: '(Fully air conitioner)' },
];


const Facilities = () => {
  return (
    <div id='facility'>
    <div className="flex items-center justify-center px-5">
      <div className="w-full max-w-5xl mx-auto py-10">
        <h2 className="text-4xl font-bold text-center mb-10">Work Space Facilities</h2>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-10">
          {facilities.map((facility, index) => (
            <div key={index} className="zoom-in flex items-center space-x-4">
              <img src={facility.icon} alt={facility.text} className="zoom-in w-16 h-16" />
              <div>
                <p className="text-xl font-bold">{facility.text}</p>
                {facility.subText && <p className="text-sm text-gray-500">{facility.subText}</p>}
              </div>
            </div>
          ))}


          {/* {facilities.map((facility, index) => (
            <div key={index} className="zoom-in flex items-center space-x-4">
              <img src={facility.icon} alt={facility.text} className="zoom-in w-16 h-16" />
              <p className="text-xl">{facility.text}</p>
            </div>
          ))} */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Facilities;
