import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/formimg.svg'; // Ensure the path is correct
import emailjs from '@emailjs/browser';
import './style.css'; // Adjust the path if your CSS file is in a different directory

const FormSection = () => {

  const [fullName, setFullName] = useState('');
  const [bookingType, setBookingType] = useState('');
  const [seatCount, setSeatCount] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');



  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };
   
  const handelSeatCount = (event) => {
    setSeatCount(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const currentDate = new Date().toISOString().split('T')[0];

 
  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      fullName,
      email,
      phoneNumber,
      bookingType,
      additionalInfo: bookingType === 'Workspace' 
      ? `Seat Count: ${seatCount}` 
      : `Booking Date: ${selectedDate}`
    };

    console.log(templateParams);

    emailjs.send('service_lhnr9b7', 'template_xnspgx4', templateParams, 'fjMtdzeL2ClCQEoxd')
      .then((response) => {
        alert('Email sent successfully!');
        console.log('Email sent:', response.status, response.text);
      })
      .catch((error) => {
        alert('Error sending email.');
        console.error('Error:', error);
      });
  };
  

  return (
    <div id="contact">
      <div className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-20 w-full tracking-wide min-h-[820px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          srcSet={backgroundImage}
          className="object-cover absolute inset-0 size-full"
        />
        <div className="relative z-10 flex items-center justify-end w-full h-full pr-10 py-20">
          <div className="bg-gray-800 bg-opacity-50 p-10 rounded-lg max-w-md w-full mr-10">
            <h2 className="text-2xl font-bold text-white mb-5">Schedule a Visit</h2>
            <form  onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-white mb-2" htmlFor="fullName">
                  Full Name:
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  value={fullName}
                  onChange={handleFullNameChange}
                  required
                  title="Please enter a valid full name"
                />
              </div>

              <div className="mb-4">
                <label className="block text-white mb-2" htmlFor="phoneNumber">
                  Phone Number:
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a valid phone number in format XXX-XXX-XXXX"
                />
              </div>

              <div className="mb-4">
                <label className="block text-white mb-2" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Please enter a valid email address"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2" htmlFor="seats">
                  Booking Type:
                </label>
                <select
                  id="seats"
                  onChange={(e) => setBookingType(e.target.value)}
                  name="seats"
                  className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  required
                >
                  <option value="">Select a type</option>
                  <option value="Event/ conference">Event/ conference</option>
                  <option value="Workspace">Workspace</option>

                </select>
              </div>

              {(bookingType === "Workspace") && <div className="mb-4">
                <label className="block text-white mb-2" htmlFor="seats">
                  Seats:
                </label>
                <select
                  id="seats"
                  onChange={(e) => setSeatCount(e.target.value)}
                  name="seats"
                  className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  required
                >
                  <option value="">No of Seats</option>
                  <option value="1-2 Seats">1-2 Seats</option>
                  <option value="5-10 Seats">5-10 Seats</option>
                  <option value="10-20 Seats">10-20 Seats</option>
                  <option value="20-50 Seats">20-50 Seats</option>
                  <option value="50 & Above Seats">50 & Above Seats</option>
                </select>
              </div>}

              {(bookingType === "Event/ conference") &&
                <div className="mb-4">
                  <label className="block text-white mb-2" htmlFor="date">
                    Select Date:
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    className="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                    value={selectedDate}
                    onChange={handleDateChange}
                    required
                    min={currentDate}
                  />
                </div>}




              <div className="zoom-in flex justify-center py-2">
                <button
                  type="submit"
                  className="w-full px-6 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

}
export default FormSection;