import React from "react";
import one from './../assets/1.png';
import two from './../assets/2.png';
import three from './../assets/5.png';
import four from './../assets/4.png';



const WorkSpace = () => {
    return(
        
<div className="w-full flex items-center justify-center px-[20px]">
  <div className="w-full py-10">
    <h2 className="text-4xl font-bold text-center mb-10">Browse By Workspace</h2>
    <div className="flex flex-col md:flex-row md:space-x-5 md:space-y-0 space-y-5 items-center justify-center">
      <a href="#contact">
        <img src={one} alt="Workspace 1" className="zoom-in h-[388px] w-[392px] object-cover" />
      </a>
      <a href="#contact">
        <img src={two} alt="Workspace 2" className="zoom-in h-[388px] w-[392px] object-cover" />
      </a>
      <a href="#contact">
        <img src={three} alt="Workspace 3" className="zoom-in h-[388px] w-[392px] object-cover" />
      </a>
      <a href="#contact">
        <img src={four} alt="Workspace 4" className="zoom-in h-[388px] w-[392px] object-cover" />
      </a>
    </div>
  </div>
</div>



    );
}

export default WorkSpace;