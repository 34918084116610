import React from 'react';

const NewsletterSection = () => {
  // return (
  //   <div id='location'>
  //   <div className="bg-white py-16">
  //     <div className="container mx-auto flex flex-col lg:flex-row items-center">
  //       {/* Map Section */}
  //       <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
  //         <iframe 
  //           className="w-full h-96 lg:h-full" 
  //           src="https://www.google.com/maps/embed/v1/view?key=AIzaSyCeV4tAAnF0MUsAiJL1vMBN-4AOzpIrj0A&center=19.10427497103076,72.88515234911691&zoom=15"
  //           frameBorder="0" 
  //           allowFullScreen 
  //           aria-hidden="false" 
  //           tabIndex="0">
  //         </iframe>
         
  //       </div>
  //       {/* Newsletter Section */}
  //       <div className="w-full lg:w-1/2 px-4 lg:px-16">
  //         <h2 className="text-3xl font-bold mb-4">Subs To News Letter</h2>
  //         <p className="text-gray-600 mb-6">
  //           Generate Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore
  //         </p>
  //         <form className="flex mb-4">
  //           <input 
  //             type="email" 
  //             placeholder="Email Address..." 
  //             className="w-full p-3 border border-gray-300 rounded-l-md focus:outline-none" 
  //           />
  //           <button 
  //             type="submit" 
  //             className="p-3 bg-blue-600 text-white font-semibold rounded-r-md hover:bg-blue-700"
  //           >
  //             Subscribe
  //           </button>
  //         </form>
  //         <p className="text-gray-500">We’ll never share your email with anyone else.</p>
  //       </div>
  //     </div>
  //   </div>
  //   </div>
  // );

  return (
    <div id='location'>
    <div className="bg-white py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-start">
        {/* Map Section */}
        <div className="w-full lg:w-3/5 mb-8 lg:mb-0">
          <iframe 
            className="w-full h-[600px] lg:h-[400px]" // Adjust the height as needed
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCeV4tAAnF0MUsAiJL1vMBN-4AOzpIrj0A&q=19.100579244068243,72.8843951868609&zoom=18" 
            frameBorder="0" 
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="0">
          </iframe>
        </div>
        {/* Newsletter Section */}
        <div className="w-full lg:w-2/5 px-4 lg:px-16">
          <h2 className="text-3xl font-bold mb-4">Subs To News Letter</h2>
          {/* <p className="text-gray-600 mb-6">
            Generate Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore
          </p> */}
          <form className="flex mb-4">
            <input 
              type="email" 
              placeholder="Email Address..." 
              className="w-full p-3 border border-gray-300 rounded-l-md focus:outline-none" 
            />
            <button 
              type="submit" 
              className="p-3 bg-blue-600 text-white font-semibold rounded-r-md hover:bg-blue-700"
            >
              Subscribe
            </button>
          </form>
          <p className="text-gray-500">We’ll never share your email with anyone else.</p>
        </div>
      </div>
    </div>
    </div>
  );
  
};

export default NewsletterSection;
