import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../assets/second.png'; // Ensure the path is correct
import Clock from '../assets/clock.svg';
import Snack from '../assets/snack.svg';
import Game from '../assets/game.svg';

const SecondSection = () => {

  const controls = useAnimation();
  const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
  });

  useEffect(() => {
      if (inView) {
          controls.start('visible');
      }
  }, [controls, inView]);

  const fadeInUp = {
      hidden: { opacity: 0, y: 50 },
      visible: {
          opacity: 1,
          y: 0,
          transition: { duration: 0.8, ease: "easeOut" }
      }
  };

  return (
    <div id='about'>
        <div ref={ref} className="flex items-center justify-center px-5">
            <div className="w-full max-w-5xl mx-auto px-5 mt-20 max-md:mt-10">
                <motion.div
                    className="flex flex-col gap-y-10 md:flex-row md:gap-x-10"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                >
                    <motion.div className="flex flex-col w-full md:w-3/4">
                        <img
                            loading="lazy"
                            src={backgroundImage}
                            className="zoom-in grow mt-1.5 w-full object-cover max-md:mt-9 max-md:max-w-full"
                            alt="Workspace"
                        />
                    </motion.div>
                    <motion.div className="flex flex-col w-full md:w-1/2 ml-5 md:ml-0">
                        <div className="zoom-in text-4xl font-semibold tracking-wide leading-10 text-zinc-800">
                            Best Creative Working Environments That Suit Your Business.
                        </div>
                        <div className="zoom-in mt-6 text-lg tracking-wide leading-7 text-zinc-500">
                            Explore our spaces designed to inspire creativity and boost productivity in a dynamic and supportive environment.
                        </div>
                        <div className="flex flex-col gap-6 mt-7">
                            <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
                                <img
                                    loading="lazy"
                                    src={Clock}
                                    className="zoom-in shrink-0 w-16 h-16 object-contain"
                                    alt="Open 24 hours"
                                />
                                <div className="flex flex-col">
                                    <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
                                        Open 24 hours
                                    </div>
                                    <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
                                        Access our facilities at any time that suits your schedule, ensuring you can work during your most productive hours without restriction.
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
                                <img
                                    loading="lazy"
                                    src={Snack}
                                    className="zoom-in shrink-0 w-16 h-16 object-contain"
                                    alt="24 Hours Housekeeping"
                                />
                                <div className="flex flex-col">
                                    <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
                                        24 Hours Housekeeping
                                    </div>
                                    <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
                                        Enjoy a clean and well-maintained workspace at all hours with our dedicated housekeeping services.
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
                                <img
                                    loading="lazy"
                                    src={Game}
                                    className="zoom-in shrink-0 w-16 h-16 object-contain"
                                    alt="Enjoy Cafeteria Zone"
                                />
                                <div className="flex flex-col">
                                    <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
                                        Enjoy Cafeteria Zone
                                    </div>
                                    <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
                                        Relax and refuel in our spacious cafeteria, a perfect spot for informal meetings or a quick break.
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    </div>
);

//   return (
//     <div id='about'>
//     <div ref={ref} className="flex items-center justify-center px-5">
//         <div className="w-full max-w-5xl mx-auto px-5 mt-20 max-md:mt-10">
//             <motion.div
//                 className="flex flex-col gap-y-10 md:flex-row md:gap-x-10"
//                 initial="hidden"
//                 animate={controls}
//                 variants={fadeInUp}
//             >
//                 <motion.div className="flex flex-col w-full md:w-3/4">
//                     <img
//                         loading="lazy"
//                         src={backgroundImage}
//                         className="zoom-in grow mt-1.5 w-full aspect-[1.15] max-md:mt-9 max-md:max-w-full"
//                         alt="Workspace"
//                     />
//                 </motion.div>
//                 <motion.div className="flex flex-col w-full md:w-1/2 ml-5 md:ml-0">
//                     <div className="zoom-in text-4xl font-semibold tracking-wide leading-10 text-zinc-800">
//                     Best Creative Working Environments That Suit Your Business.
//                     </div>
//                     <div className="zoom-in mt-6 text-lg tracking-wide leading-7 text-zinc-500">
//                     Explore our spaces designed to inspire creativity and boost productivity in a dynamic and supportive environment.
//                     </div>
//                     <div className="flex flex-col gap-6 mt-7">
//                         <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
//                             <img
//                                 loading="lazy"
//                                 src={Clock}
//                                 className="zoom-in shrink-0 w-16 h-16"
//                                 alt="Open 24 hours"
//                             />
//                             <div className="flex flex-col">
//                                 <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
//                                     Open 24 hours
//                                 </div>
//                                 <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
//                                 Access our facilities at any time that suits your schedule, ensuring you can work during your most productive hours without restriction.
//                                 </div>
//                             </div>
//                         </motion.div>
//                         <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
//                             <img
//                                 loading="lazy"
//                                 src={Snack}
//                                 className="zoom-in shrink-0 w-16 h-16"
//                                 alt="Free Snack & Drink"
//                             />
//                             <div className="flex flex-col">
//                                 <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
//                                 24 Hours Housekeeping                                </div>
//                                 <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
//                                 Enjoy a clean and well-maintained workspace at all hours with our dedicated housekeeping services.
//                                 </div>
//                             </div>
//                         </motion.div>
//                         <motion.div className="zoom-in flex items-start gap-5" variants={fadeInUp}>
//                             <img
//                                 loading="lazy"
//                                 src={Game}
//                                 className="zoom-in shrink-0 w-16 h-16"
//                                 alt="Enjoy Cafeteria Zone"
//                             />
//                             <div className="flex flex-col">
//                                 <div className="zoom-in text-2xl font-bold tracking-wide leading-6 text-zinc-800">
//                                     Enjoy Cafeteria Zone
//                                 </div>
//                                 <div className="zoom-in mt-4 text-lg tracking-wide leading-7 text-zinc-500">
//                                 Relax and refuel in our spacious cafeteria, a perfect spot for informal meetings or a quick break.
//                                 </div>
//                             </div>
//                         </motion.div>
//                     </div>
//                 </motion.div>
//             </motion.div>
//         </div>
//     </div>
//     </div>
// );
};



    // return(
    //     <div className="flex items-center justify-center px-5">
    //     <div className="w-full max-w-5xl mx-auto px-5 mt-20 max-md:mt-10">
    //     <div className="flex flex-col gap-y-10 md:flex-row md:gap-x-10">
    //         <div className="flex flex-col w-full md:w-3/4">
    //           <img
    //             loading="lazy"
    //             src={backgroundImage}
    //             className=" zoom-in grow mt-1.5 w-full aspect-[1.15] max-md:mt-9 max-md:max-w-full"
    //             alt="Workspace"
    //           />
    //         </div>
    //         <div className="flex flex-col w-full md:w-1/2 ml-5 md:ml-0">
    //           <div className=" zoom-in text-4xl font-semibold tracking-wide leading-10 text-zinc-800">
    //             Best creative working environments that suits your business.
    //           </div>
    //           <div className=" zoom-in mt-6 text-lg tracking-wide leading-7 text-zinc-800">
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    //           </div>
    //           <div className="flex flex-col gap-6 mt-7">
    //             <div className="zoom-in flex items-start gap-5">
    //               <img
    //                 loading="lazy"
    //                 src={Clock}
    //                 className="shrink-0 w-16 h-16"
    //                 alt="Open 24 hours"
    //               />
    //               <div className="flex flex-col">
    //                 <div className="text-2xl font-bold tracking-wide leading-6 text-zinc-800">
    //                   Open 24 hours
    //                 </div>
    //                 <div className="mt-4 text-lg tracking-wide leading-7 text-zinc-800">
    //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="zoom-in flex items-start gap-5">
    //               <img
    //                 loading="lazy"
    //                 src={Snack}
    //                 className="shrink-0 w-16 h-16"
    //                 alt="Free Snack & Drink"
    //               />
    //               <div className="flex flex-col">
    //                 <div className="text-2xl font-bold tracking-wide leading-6 text-zinc-800">
    //                   Free Snack & Drink
    //                 </div>
    //                 <div className="mt-4 text-lg tracking-wide leading-7 text-zinc-800">
    //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="zoom-in flex items-start gap-5">
    //               <img
    //                 loading="lazy"
    //                 src={Game}
    //                 className="shrink-0 w-16 h-16"
    //                 alt="Enjoy Cafeteria Zone"
    //               />
    //               <div className="flex flex-col">
    //                 <div className="text-2xl font-bold tracking-wide leading-6 text-zinc-800">
    //                   Enjoy Cafeteria Zone
    //                 </div>
    //                 <div className="mt-4 text-lg tracking-wide leading-7 text-zinc-800">
    //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    // );


export default SecondSection;
