// src/EventsPage.js
import React from 'react';
import './event.css'; // Ensure the path is correct
import eventOne from './../assets/event1.svg';
import eventTwo from './../assets/event2.svg';
import eventThree from './../assets/event3.svg';

const EventsPage = () => {
  return (
    <div id='event'>
    <div className="events-container">
      <h2>Our Events</h2>
      <p>Step into the heart of our community with Cowork Collision’s diverse events.</p>
      <div className="events-gallery">
        <img src={eventOne} alt="Event 1" className="event-image" />
        <img src={eventTwo} alt="Event 2" className="event-image" />
        <img src={eventThree} alt="Event 3" className="event-image" />
      </div>
      {/* <button className="more-posts-btn">More Posts</button> */}
    </div>
    </div>
  );
};

export default EventsPage;
