import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/4 mb-4">
            <ul>
              <li><a href="#home" className="text-gray-400 hover:text-white">Home</a></li>
              <li><a href="#about" className="text-gray-400 hover:text-white">About Us</a></li>
              <li><a
                href="https://www.google.com/maps/dir//BUILDING,+Block+No.+201,+Mehera+Industrial+Compound+V.+S.+P,+Andheri+-+Kurla+Rd,+Saki+Naka,+Andheri,+Maharashtra+400072/@19.1007064,72.8018966,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7c992c73dee83:0xc0718c760c7ddbf6!2m2!1d72.8842983!2d19.1007247?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">Location</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-4">
            <ul>
              <li><a href="#facility" className="text-gray-400 hover:text-white">Facilities</a></li>
              <li><a href="#contact" className="text-gray-400 hover:text-white">Contact Us</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-4">
            <h5 className="text-lg font-semibold">Social</h5>
            <ul>
              <li><a href="https://x.com/Coworkcollision"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">Twitter</a></li>
              <li><a href="https://www.instagram.com/cowork_collision/?igsh=ZHh1dGhuOHJubTFu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">Instagram</a></li>
              <li><a href="https://www.linkedin.com/in/cowork-collision-75a142313/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">Linkedin</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-4">
            <h5 className="text-lg font-semibold">Contact</h5>
            <ul>
              <li><a href="tel:+919820833383" className="text-gray-400 hover:text-white">Phone: +91-9820833383</a></li>
              <li><a
                href="https://www.google.com/maps/dir//BUILDING,+Block+No.+201,+Mehera+Industrial+Compound+V.+S.+P,+Andheri+-+Kurla+Rd,+Saki+Naka,+Andheri,+Maharashtra+400072/@19.1007064,72.8018966,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7c992c73dee83:0xc0718c760c7ddbf6!2m2!1d72.8842983!2d19.1007247?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">Address: BUILDING, Block No. 201, Mehera Industrial Compound V. S. P, Andheri - Kurla Rd, Saki Naka, Andheri, Maharashtra 400072</a></li>

            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center">
          <div className="text-lg font-bold">CoWork Collision</div>
          <div>
            <a
              href="https://99yrs.com/"
              className="text-gray-500 hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              © 2024, Made with passion by 99yrs Network LLP
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
