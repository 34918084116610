// import React from "react";
// import Slider from 'react-slick';
// import './custom-slick.css'; // Add custom CSS for additional styling if needed
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


// const testimonials = [
//     {
//         name: '99yrs Network LLP',
//         title: 'Cafe Owner',
//         image: 'path/to/image1.jpg', // Replace with your image path
//         rating: 5,
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.',
//         background: 'bg-sky-200'
//     },
//     {
//         name: 'Riana Madiva',
//         title: 'Digital Marketer',
//         image: 'path/to/image2.jpg', // Replace with your image path
//         rating: 5,
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.',
//         background: 'bg-white'
//     },
//     {
//         name: 'John Melon',
//         title: 'Freelancer',
//         image: 'path/to/image3.jpg', // Replace with your image path
//         rating: 5,
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.',
//         background: 'bg-sky-200'
//     },
//     {
//         name: 'Riana Madiva',
//         title: 'Digital Marketer',
//         image: 'path/to/image2.jpg', // Replace with your image path
//         rating: 5,
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.',
//         background: 'bg-white'
//     },
// ];

// const TestSection = () => {

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         centerMode: true,
//         centerPadding: '0',
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 1,
//               infinite: true,
//               dots: true,
//               centerMode: false,
//             },
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               centerMode: false,
//             },
//           },
//         ],
//       };


//       return (
//         <div className="flex items-center justify-center">
//           <div className="w-full max-w-6xl mx-auto">
//         <h2 className="text-4xl font-bold text-center mb-10">They Satisfied With Our Coworking Space</h2>
//         <Slider {...settings}>
//           {testimonials.map((testimonial, index) => (
//             <div key={index} className="p-4">
//               <div className={`shadow-lg rounded-lg p-6 ${testimonial.background}`}>
//                 <div className="flex items-center mb-4">
//                   <div className="w-16 h-16 mr-4 text-5xl text-gray-400">
//                     <i className="fas fa-user-circle"></i>
//                   </div>
//                   <div>
//                     <h3 className="text-xl font-bold">{testimonial.name}</h3>
//                     <p className="text-gray-600">{testimonial.title}</p>
//                   </div>
//                 </div>
//                 <div className="flex mb-4">
//                   {Array(testimonial.rating)
//                     .fill()
//                     .map((_, i) => (
//                       <svg
//                         key={i}
//                         className="w-5 h-5 text-orange-500"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                       >
//                         <path d="M9.049 2.927C9.43 1.84 10.57 1.84 10.95 2.927l1.562 4.792a1 1 0 00.95.69h5.045c.86 0 1.219 1.104.588 1.657l-4.093 3.551a1 1 0 00-.292 1.092l1.562 4.792c.381 1.087-.993 1.98-1.907 1.39L10 16.11l-4.093 3.551c-.914.59-2.288-.303-1.907-1.39l1.562-4.792a1 1 0 00-.292-1.092L1.177 9.366c-.63-.553-.272-1.657.588-1.657h5.045a1 1 0 00.95-.69l1.562-4.792z" />
//                       </svg>
//                     ))}
//                 </div>
//                 <p className="text-gray-600">{testimonial.text}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );



//     // return (
//     //     <div className="flex items-center justify-center">
//     //         <div className="w-full max-w-6xl mx-auto">
//     //             <h2 className="text-4xl font-bold text-center mb-10">They Satisfied With Our Coworking Space</h2>
//     //             <Slider {...settings}>
//     //   {testimonials.map((testimonial, index) => (
//     //     <div key={index} className="p-4">
//     //       <div className={`shadow-lg rounded-lg p-6 ${testimonial.background}`}>
//     //         <div className="flex items-center mb-4">
//     //           <div className="w-16 h-16 mr-4 text-5xl text-gray-400">
//     //             <i className="fas fa-user-circle"></i>
//     //           </div>
//     //           <div>
//     //             <h3 className="text-xl font-bold">{testimonial.name}</h3>
//     //             <p className="text-gray-600">{testimonial.title}</p>
//     //           </div>
//     //         </div>
//     //         <div className="flex mb-4">
//     //           {Array(testimonial.rating)
//     //             .fill()
//     //             .map((_, i) => (
//     //               <svg
//     //                 key={i}
//     //                 className="w-5 h-5 text-orange-500"
//     //                 fill="currentColor"
//     //                 viewBox="0 0 20 20"
//     //               >
//     //                 <path d="M9.049 2.927C9.43 1.84 10.57 1.84 10.95 2.927l1.562 4.792a1 1 0 00.95.69h5.045c.86 0 1.219 1.104.588 1.657l-4.093 3.551a1 1 0 00-.292 1.092l1.562 4.792c.381 1.087-.993 1.98-1.907 1.39L10 16.11l-4.093 3.551c-.914.59-2.288-.303-1.907-1.39l1.562-4.792a1 1 0 00-.292-1.092L1.177 9.366c-.63-.553-.272-1.657.588-1.657h5.045a1 1 0 00.95-.69l1.562-4.792z" />
//     //               </svg>
//     //             ))}
//     //         </div>
//     //         <p className="text-gray-600">{testimonial.text}</p>
//     //       </div>
//     //     </div>
//     //   ))}
//     // </Slider>
//     //         </div>
//     //     </div>
//     // );
// }

// export default TestSection;



// src/TestimonialsCarousel.js

import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Testmo.css';

const testimonials = [
  // {
  //   name: 'Yatra Film',
  //   title: 'Film production',
  //   image: 'path/to/image1.jpg', // Replace with your image path
  //   rating: 5,
  //   text: 'Supportive Management and staff , Feeling working in own office.',
  //   background: 'bg-sky-200'
  // },
  {
    name: 'Emcure Pharmaceutical',
    title: 'Pharmaceutical company',
    image: 'path/to/image2.jpg', // Replace with your image path
    rating: 4,
    text: 'It was great experience and nice services provided by CoWork Collision. Great services with Kind gesture of staff.',
    background: 'bg-white'
  },
  {
    name: 'Playbulous',
    title: 'IT Services',
    image: 'path/to/image3.jpg', // Replace with your image path
    rating: 5,
    text: 'Great Support while opening office at Mumbai, Very affordable Prices with best Amenities, Best for Growing companies where you can increase the team members at anytime.',
    background: 'bg-sky-200'
  },
  {
    name: '99yrs Network LLP',
    title: 'E - commerce agency',
    image: 'path/to/image2.jpg', // Replace with your image path
    rating: 5,
    text: 'Our company had a great experience with CoWork Collision. Excellent services and professional staff. Highly recommended!',
    background: 'bg-white'
  },
];

const TestimonialsCarousel = () => {

  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth < 768) {
        setItemsToShow(1);
      } else {
        setItemsToShow(3);
      }
    };

    updateItemsToShow();
    window.addEventListener('resize', updateItemsToShow);
    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  return (
    <div className="testimonials-carousel py-16 bg-gray-100">
      <h2 className="text-4xl font-bold text-center mb-10">They Satisfied With Our Coworking Space</h2>
      {/* <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false} // Hide the dots
        autoPlay={true}
        interval={5000}
        centerMode={itemsToShow > 1}
        centerSlidePercentage={itemsToShow > 1 ? 33.33 : 100} // Adjust the percentage
        className="max-w-6xl mx-auto"
      >
        {testimonials.map((testimonial, index) => (
          <div key={index} className="p-4">
            <div className={`shadow-lg rounded-lg p-6 ${testimonial.background}`}>
            <div className="flex items-center mb-4">
                <div className="w-16 h-16 mr-4 text-5xl text-gray-400 flex items-center">
                  <i className="fas fa-user-circle"></i>
                </div>
                <div className="flex flex-col items-start">
                  <h3 className="text-xl font-bold">{testimonial.name}</h3>
                  <p className="text-gray-600">{testimonial.title}</p>
                </div>
              </div>  
              <div className="flex mb-4">
                {Array(testimonial.rating)
                  .fill()
                  .map((_, i) => (
                    <svg
                      key={i}
                      className="w-5 h-5 text-orange-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927C9.43 1.84 10.57 1.84 10.95 2.927l1.562 4.792a1 1 0 00.95.69h5.045c.86 0 1.219 1.104.588 1.657l-4.093 3.551a1 1 0 00-.292 1.092l1.562 4.792c.381 1.087-.993 1.98-1.907 1.39L10 16.11l-4.093 3.551c-.914.59-2.288-.303-1.907-1.39l1.562-4.792a1 1 0 00-.292-1.092L1.177 9.366c-.63-.553-.272-1.657.588-1.657h5.045a1 1 0 00.95-.69l1.562-4.792z" />
                    </svg>
                  ))}
              </div>
              <p className="text-gray-600">{testimonial.text}</p>
            </div>
          </div>
        ))}
      </Carousel> */}

<Carousel
  showArrows={true}
  infiniteLoop={true}
  showThumbs={false}
  showStatus={false}
  showIndicators={false} // Hide the dots
  autoPlay={true}
  interval={5000}
  centerMode={itemsToShow > 1}
  centerSlidePercentage={itemsToShow > 1 ? 33.33 : 100} // Adjust the percentage
  className="max-w-6xl mx-auto"
>
  {testimonials.map((testimonial, index) => (
    <div key={index} className="p-4">
      <div className={`shadow-lg rounded-lg p-6 ${testimonial.background} flex flex-col items-start justify-between h-72 w-72`} style={{ height: '300px', width: '300px' }}>
        <div className="flex items-center mb-4 w-full">
          <div className="w-16 h-16 mr-4 text-5xl text-gray-400 flex items-center justify-center">
            <i className="fas fa-user-circle"></i>
          </div>
          <div className="flex flex-col items-start">
            <h3 className="text-xl font-bold text-left">{testimonial.name}</h3>
            <p className="text-gray-600 text-left">{testimonial.title}</p>
          </div>
        </div>  
        <div className="flex mb-4 w-full">
          {Array(testimonial.rating)
            .fill()
            .map((_, i) => (
              <svg
                key={i}
                className="w-5 h-5 text-orange-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M9.049 2.927C9.43 1.84 10.57 1.84 10.95 2.927l1.562 4.792a1 1 0 00.95.69h5.045c.86 0 1.219 1.104.588 1.657l-4.093 3.551a1 1 0 00-.292 1.092l1.562 4.792c.381 1.087-.993 1.98-1.907 1.39L10 16.11l-4.093 3.551c-.914.59-2.288-.303-1.907-1.39l1.562-4.792a1 1 0 00-.292-1.092L1.177 9.366c-.63-.553-.272-1.657.588-1.657h5.045a1 1 0 00.95-.69l1.562-4.792z" />
              </svg>
            ))}
        </div>
        <p className="text-gray-600 text-left w-full">{testimonial.text}</p>
      </div>
    </div>
  ))}
</Carousel>


    </div>
  );
};

export default TestimonialsCarousel;
